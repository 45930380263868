body.page-about
  background $white

aside.about-backtotop,
aside.legals-backtotop
  display none
  &.visible
    display block
  +mobile()
    &.visible
      display none

body.page-about .site-nav ul
  +desktop()
    background transparent
    position relative
  +mobile()
    background $white
    position fixed

.content-about
  margin-bottom 0.1em
  +desktop()
    margin-top 55px
    padding-left 70px
    padding-right 70px

.content-about article
  display block
  p
    margin-bottom 0.8em
  a[href^="mailto:"]
    display inline-block
    position relative
    &:after
      position absolute
      z-index -1
      content ''
      bottom 0.21em
      left 0
      width 100%
      height 0.08em
      background $black

  +desktop()
    font-size calc(8px + 1.2vw) 
    // font-size 23.5px // 35px
    line-height 1.3em
    margin-bottom 1.3em
    max-width 35em

