body.page-legals
  background $white

aside.legals-backtotop
  display none
  &.visible
    display block
  +mobile()
    &.visible
      display none

body.page-legals .site-nav ul
  +desktop()
    background transparent
    position relative
  +mobile()
    background $white
    position fixed

.content-legals
  margin-bottom 3em
  +desktop()
    margin-top 55px
    padding-left 70px
    padding-right 70px

.content-legals article
  display block
  p
    margin-bottom 0.8em
  +desktop()
    font-size calc(8px + 1.2vw) 
    line-height 1.3em
    margin-bottom 1.3em
    max-width 35em

