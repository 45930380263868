main.content-projects {
  padding: 0 70px 70px;
}
@media (max-width: 989px) {
  main.content-projects {
    padding: 0 15px 40px;
  }
}
main.content-projects .project-list {
  margin: 0 auto;
  width: 100%;
}
@media (min-width: 990px) {
  main.content-projects .project {
    display: inline-block;
    flex-shrink: 0;
    width: 31.4%;
  }
  main.content-projects .project:nth-child(3n+1) {
    margin-right: calc((100% - 31.4% * 3) * 0.5);
  }
  main.content-projects .project:nth-child(3n+2) {
    margin-right: calc((100% - 31.4% * 3) * 0.5 - 1px);
  }
  main.content-projects .project:nth-child(n+4) {
    margin-top: calc((100% - 31.4% * 3.03) * 0.5);
  }
}
@media (max-width: 989px) {
  main.content-projects .project {
    margin-bottom: 6%;
  }
}
main.content-projects .project-thumbnail {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding-top: 67%;
}
main.content-projects .project-name {
  display: none;
  margin-top: 15px;
}
@media (max-width: 989px) {
  main.content-projects .project-name {
    display: block;
  }
}
/*# sourceMappingURL=src/components/Projects/Projects.css.map */