body.page-legals {
  background: #fff;
}
aside.legals-backtotop {
  display: none;
}
aside.legals-backtotop.visible {
  display: block;
}
@media (max-width: 989px) {
  aside.legals-backtotop.visible {
    display: none;
  }
}
@media (min-width: 990px) {
  body.page-legals .site-nav ul {
    background: transparent;
    position: relative;
  }
}
@media (max-width: 989px) {
  body.page-legals .site-nav ul {
    background: #fff;
    position: fixed;
  }
}
.content-legals {
  margin-bottom: 3em;
}
@media (min-width: 990px) {
  .content-legals {
    margin-top: 55px;
    padding-left: 70px;
    padding-right: 70px;
  }
}
.content-legals article {
  display: block;
}
.content-legals article p {
  margin-bottom: 0.8em;
}
@media (min-width: 990px) {
  .content-legals article {
    font-size: calc(8px + 1.2vw);
    line-height: 1.3em;
    margin-bottom: 1.3em;
    max-width: 35em;
  }
}
/*# sourceMappingURL=src/components/Legals/Legals.css.map */