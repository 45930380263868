.language-selector {
  position: fixed;
  text-transform: uppercase;
  bottom: 0;
}
@media (max-width: 989px) {
  .language-selector {
    display: none;
  }
}
.language-selector > .mq-desktop span:not(.active) {
  display: block;
}
.language-selector > .mq-desktop span.active {
  display: none;
}
.language-selector > .mq-desktop:hover span:not(.active) {
  display: none;
}
.language-selector > .mq-desktop:hover span.active {
  display: block;
}
.language-selector > .mq-mobile > span {
  color: #c1c1c1;
}
@media (max-width: 989px) {
  body.menu--visible .language-selector {
    display: block;
    background: #fff;
    height: 45px;
    width: 100%;
    left: 0;
    right: 0;
  }
  body.menu--visible .language-selector div {
    bottom: 5px;
  }
}
/*# sourceMappingURL=src/components/Language/language.css.map */