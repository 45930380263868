$mq-mobile = 990px

mobile()
  +below-width($mq-mobile)
    {block}

desktop()
  +above-width($mq-mobile - 1px)
    {block}

above-height(break)
  @media (min-height: (break + 1))
    {block}

below-height(break)
  @media (max-height: (break - 1))
    {block}

between-height(min, max)
  @media (min-height: min) and (max-height: max)
    {block}

above-width(break)
  @media (min-width: (break + 1))
    {block}

below-width(break)
  @media (max-width: (break - 1))
    {block}

between-width(min, max)
  @media (min-width: min) and (max-width: max)
    {block}

retina()
  @media not all, only screen and (min-resolution: 1.5dppx), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi)
    {block}