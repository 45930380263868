body.menu--visible .project-infos
  display none

.project-infos
  strong
    font-weight inherit
    font-style italic
  .infos-content
    display none

  .infos-link
    user-select none
  .infos-link .link--closed
    display inline-block
    transform translateY(1px)
  .infos-link .link--open
    display none
    transform translateY(-1px)
  .infos-link .link--open img
    height 18px
    width auto
    vertical-align middle

  body.project-infos--visible & 
    .infos-content
      display block
    +mobile()
      .link--closed
        display none
      .link--open
        display inline-block
  .infos-content > *:not(:first-child)
    margin-top 1.1em
  .infos-content a
    text-decoration underline

  +desktop()
    .infos-link,
    .infos-content
      position fixed
      width calc(10vw + 85px) // CONTENT RESIZE
      max-width 240px
      left 15px
    .infos-link
      bottom 9px
    .infos-content
      bottom 70px
      font-size 12px
      line-height 1.2em
  +mobile()
    .infos-content
      padding 9.5px 15px 90px
      & p:first-of-type
        margin-bottom 34.5px

    .infos-link
      z-index 10
      position fixed
      background white
      height 45px
      line-height 45px
      bottom 0
      left 0
      right 0
      width 100%
      text-align center
      

.content-project
  body.project-infos--visible &
    +mobile()
      display none

  .project-section
    margin-bottom 11%

  .section-vimeo
    position relative
    width 100%

  .section-img
    position relative
    width 100%
    background #fff

  .section-img img
    display block
    margin 0 auto
    position absolute
    top 0
    left 0
    right 0
    width 100%
    height auto

  .section-img img.portrait
    width 65%