.language-selector
  position fixed
  text-transform uppercase
  bottom 0
  +mobile()
    display none

.language-selector > .mq-desktop
  & span:not(.active)
    display block
  & span.active
    display none
  &:hover
    & span:not(.active)
      display none
    & span.active
      display block

.language-selector > .mq-mobile
  & > span
    color $grey

body.menu--visible .language-selector
  +mobile()
    display block
    background white
    height 45px
    width 100%
    left 0
    right 0
    div
      bottom 5px