main.content-projects
  padding 0 70px 70px
  +mobile()
    padding 0 15px 40px
  // +above-width(1400px)
  //   padding 0 80px 70px
  .project-list
    margin 0 auto
    width 100%
    // +above-width(1400px)
    //   width 75%

  .project
    +desktop()
      $size = 31.4%
      display inline-block
      flex-shrink 0
      width $size 
      &:nth-child(3n+1)
        margin-right 'calc((100% - %s * 3) * 0.5)' % ($size)
      &:nth-child(3n+2)
        margin-right 'calc((100% - %s * 3) * 0.5 - 1px)' % ($size)
      &:nth-child(n+4)
        margin-top 'calc((100% - %s * 3.03) * 0.5)' % ($size)
    +mobile()
      margin-bottom 6%

  .project-thumbnail
    width 100%
    background-size cover
    background-repeat no-repeat
    background-position center center
    padding-top 67%

  .project-name
    display none
    margin-top 15px
    +mobile()
      display block