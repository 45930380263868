body.project-infos--visible .site-nav
  +mobile()
    display none

.site-nav ul
  position fixed
  z-index 10
  background $white
  padding 0 15px 0
  height 45px
  top 0
  left 0
  right 0
  line-height 45px

body.menu--visible main
  display none

body.menu--visible
  background white !important
  color black !important

body.menu--visible .site-nav ul
  background $white !important

body.menu--visible .site-nav .menu-link
  color $grey

.site-nav .nav-item--left
  float left
  margin-right 30px
  +mobile()
    margin-right 0

.site-nav .nav-item--right
  float right
  margin-left 30px
  +mobile()
    margin-left 0

// .site-nav .nav-item.active
//   font-style italic

.site-nav .nav-item.mq-desktop.nav-item--projects span
  white-space nowrap

.modal--mobilemenu
  display none

body.menu--visible .modal--mobilemenu
  display block

.site-nav--mobile
  margin-top 70px
  margin-bottom 50px
  padding 0 15px

.site-nav--mobile ul
  display flex
  flex-flow column-reverse nowrap

.site-nav--mobile .nav-item.active
  font-style normal

.site-nav--mobile li
  flex-grow 0
  flex-shrink 0

.site-nav--mobile li:not(:last-child)
  margin-top 5px
