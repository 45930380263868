.page-home
  overflow hidden
  +mobile()
    position fixed

.content-home
  position fixed
  margin 0
  left 0
  right 0
  margin-bottom 0
  overflow hidden
  top 70px
  bottom 70px

.content-home .home-highlights
  width 100%
  height 100%
  // padding-top 70.3125%
  position relative
  overflow hidden

.content-home .zone
  z-index 2
  width 50%
  height 100%
  position absolute
  top 0
  left 0

.content-home .zone-prev
  cursor w-resize

.content-home .zone-next
  cursor e-resize
  left auto
  right 0

.content-home .highlight
  position absolute
  top 0
  left 0
  width 100%
  height 100%

.content-home .highlight:not(:first-of-type)
  visibility hidden

.content-home .highlight figure
  width 100%
  height 100%
  background-repeat no-repeat
  background-size contain

.home-highlight-seemore
  +mobile()
    z-index 10
    position fixed
    background white
    height 45px
    line-height 45px
    bottom 0
    left 0
    right 0
    width 100%
    text-align center
    a
      display block
      transform translateY(1px)
    