body.menu--visible .project-infos {
  display: none;
}
.project-infos strong {
  font-weight: inherit;
  font-style: italic;
}
.project-infos .infos-content {
  display: none;
}
.project-infos .infos-link {
  user-select: none;
}
.project-infos .infos-link .link--closed {
  display: inline-block;
  transform: translateY(1px);
}
.project-infos .infos-link .link--open {
  display: none;
  transform: translateY(-1px);
}
.project-infos .infos-link .link--open img {
  height: 18px;
  width: auto;
  vertical-align: middle;
}
body.project-infos--visible .project-infos .infos-content {
  display: block;
}
@media (max-width: 989px) {
  body.project-infos--visible .project-infos .link--closed {
    display: none;
  }
  body.project-infos--visible .project-infos .link--open {
    display: inline-block;
  }
}
.project-infos .infos-content > *:not(:first-child) {
  margin-top: 1.1em;
}
.project-infos .infos-content a {
  text-decoration: underline;
}
@media (min-width: 990px) {
  .project-infos .infos-link,
  .project-infos .infos-content {
    position: fixed;
    width: calc(10vw + 85px);
    max-width: 240px;
    left: 15px;
  }
  .project-infos .infos-link {
    bottom: 9px;
  }
  .project-infos .infos-content {
    bottom: 70px;
    font-size: 12px;
    line-height: 1.2em;
  }
}
@media (max-width: 989px) {
  .project-infos .infos-content {
    padding: 9.5px 15px 90px;
  }
  .project-infos .infos-content p:first-of-type {
    margin-bottom: 34.5px;
  }
  .project-infos .infos-link {
    z-index: 10;
    position: fixed;
    background: #fff;
    height: 45px;
    line-height: 45px;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 989px) {
  body.project-infos--visible .content-project {
    display: none;
  }
}
.content-project .project-section {
  margin-bottom: 11%;
}
.content-project .section-vimeo {
  position: relative;
  width: 100%;
}
.content-project .section-img {
  position: relative;
  width: 100%;
  background: #fff;
}
.content-project .section-img img {
  display: block;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
}
.content-project .section-img img.portrait {
  width: 65%;
}
/*# sourceMappingURL=src/components/Project/Project.css.map */