html {
  box-sizing: border-box;
  border-collapse: collapse;
  line-height: 1;
  font-family: sans-serif;
}
* {
  margin: 0;
  padding: 0;
  border-collapse: inherit;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
*:not(body) {
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
}
body {
  position: relative;
}
[hidden] {
  display: none !important;
}
ul,
ol,
menu {
  list-style: none;
}
a {
  color: inherit;
}
fieldset {
  border: none;
}
::selection {
  background: #fff;
  color: #c1c1c1;
}
::-moz-selection {
  background: #fff;
  color: #c1c1c1;
}
* {
  box-sizing: border-box;
}
a {
  color: inherit;
  text-decoration: none;
}
body {
  font-family: 'akzdnzgrtsk';
  font-size: 17px;
  background: #fff;
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
  position: absolute;
  line-height: 1.3em;
}
@media (max-width: 989px) {
  body {
    font-size: 20px;
  }
}
h1 {
  line-height: 1.3em;
}
main {
  display: block;
  width: 100%;
  overflow-x: hidden;
  margin-top: 70px;
  padding: 0 15px;
}
@media (max-width: 989px) {
  .mq-desktop:not(.mq-mobile) {
    display: none;
  }
}
@media (min-width: 990px) {
  .mq-mobile:not(.mq-desktop) {
    display: none;
  }
}
.fixed-bottom-left {
  position: fixed;
  bottom: 9px;
  left: 15px;
}
.fixed-bottom-right {
  position: fixed;
  bottom: 9px;
  right: 15px;
}
.visible {
  display: block;
}
.content--pictural {
  width: 52%;
  margin-right: auto;
  margin-left: auto;
}
@media (max-width: 989px) {
  .content--pictural {
    width: 100%;
  }
}
body.menu--visible .menu-affraid {
  display: none;
}
@media (max-width: 989px) {
  body.page-error aside {
    z-index: 10;
    position: fixed;
    background: #fff;
    height: 45px;
    line-height: 45px;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    text-align: center;
  }
  body.page-error aside a {
    display: block;
    transform: translateY(1px);
  }
}
/*# sourceMappingURL=src/components/App/App.css.map */