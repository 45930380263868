body.page-about {
  background: #fff;
}
aside.about-backtotop,
aside.legals-backtotop {
  display: none;
}
aside.about-backtotop.visible,
aside.legals-backtotop.visible {
  display: block;
}
@media (max-width: 989px) {
  aside.about-backtotop.visible,
  aside.legals-backtotop.visible {
    display: none;
  }
}
@media (min-width: 990px) {
  body.page-about .site-nav ul {
    background: transparent;
    position: relative;
  }
}
@media (max-width: 989px) {
  body.page-about .site-nav ul {
    background: #fff;
    position: fixed;
  }
}
.content-about {
  margin-bottom: 0.1em;
}
@media (min-width: 990px) {
  .content-about {
    margin-top: 55px;
    padding-left: 70px;
    padding-right: 70px;
  }
}
.content-about article {
  display: block;
}
.content-about article p {
  margin-bottom: 0.8em;
}
.content-about article a[href^="mailto:"] {
  display: inline-block;
  position: relative;
}
.content-about article a[href^="mailto:"]:after {
  position: absolute;
  z-index: -1;
  content: '';
  bottom: 0.21em;
  left: 0;
  width: 100%;
  height: 0.08em;
  background: #000;
}
@media (min-width: 990px) {
  .content-about article {
    font-size: calc(8px + 1.2vw);
    line-height: 1.3em;
    margin-bottom: 1.3em;
    max-width: 35em;
  }
}
/*# sourceMappingURL=src/components/About/About.css.map */