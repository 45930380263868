@import "../../../node_modules/stylus-reset"
reset()

::selection
  background white
  color $grey

::-moz-selection
  background white
  color $grey

// .page-about,
// .page-legals
//   ::selection
//     background #dec994

//   ::-moz-selection
//     background #dec994


*
  box-sizing border-box

a
  color inherit
  text-decoration none

body
  font-family 'akzdnzgrtsk'
  font-size 17px // 20px
  background white
  min-height 100vh
  width 100%
  box-sizing border-box
  position absolute
  line-height 1.3em
  +mobile()
    font-size 20px // 23px

h1
  line-height 1.3em

main
  display block
  width 100%
  overflow-x hidden
  margin-top 70px
  padding 0 15px

+mobile()
  .mq-desktop:not(.mq-mobile)
    display none

+desktop()
  .mq-mobile:not(.mq-desktop)
    display none

.fixed-bottom-left
  position fixed
  bottom 9px
  left 15px

.fixed-bottom-right
  position fixed
  bottom 9px
  right 15px

.visible
  display block

// common styles
.content--pictural
  width 52%
  margin-right auto
  margin-left auto
  +mobile()
    width 100%

body.menu--visible .menu-affraid
  display none

body.page-error aside
  +mobile()
    z-index 10
    position fixed
    background white
    height 45px
    line-height 45px
    bottom 0
    left 0
    right 0
    width 100%
    text-align center
    a
      display block
      transform translateY(1px)